<template>
  <div>
    <UploadDownload v-if="uploadProvider" @connected="showModal">
      <template #default="{ connect }">
        <slot name="trigger" :select="() => startFileSelect(connect)">
          <Button
            :text="triggerText"
            type="white"
            @click="() => startFileSelect(connect)"
          >
            <template #left>
              <img
                :src="providerObject.image"
                alt=""
                class="ifile-search-trigger-icon"
              />
            </template>
          </Button>
        </slot>
      </template>
    </UploadDownload>
    <b-modal v-model="visible" :can-cancel="['escape', 'outside']">
      <div class="ifile-search">
        <div class="ifile-search-header">
          <p class="ifile-search-header-text">
            Select files on {{ providerObject ? providerObject.title : '' }}
          </p>
          <Button icon="close" type="grey" size="xs" @click="close" />
        </div>
        <div class="ifile-search-content">
          <TextInput
            v-model="query"
            :placeholder="`Search in ${
              providerObject ? providerObject.title : ''
            }`"
            left-icon="search"
            :disabled="consumptionLoading"
            class="ifile-search-content-search"
            @submit="resetPaginatorConsumer"
          />
          <div
            ref="ifilelist"
            class="ifile-search-content-list"
            @wheel="handleScroll"
          >
            <div
              v-for="file in paginatorData"
              :key="file.uuid"
              class="ifile-search-content-list-item"
              :class="{ selected: selectedIds.includes(file.uuid) }"
              @click="() => select(file)"
            >
              <DocumentTag
                :mimetype="file.mimetype"
                class="ifile-search-content-list-item-tag"
              />
              <p class="ifile-search-content-list-item-name">{{ file.name }}</p>
              <img
                src="@/assets/icons/external-bold.svg"
                alt=""
                class="ifile-search-content-list-item-link"
                @click.stop="() => openExternal(file)"
              />
              <p class="ifile-search-content-list-item-date">
                {{ lastEdit(file) }}
              </p>
            </div>
            <div
              v-if="consumptionLoading"
              class="ifile-search-content-list-loading"
            >
              <b-loading :active="consumptionLoading" :is-full-page="false" />
            </div>
          </div>
        </div>
        <div class="ifile-search-footer">
          <Button text="Cancel" type="white" @click="close" />
          <Button text="Select" :disabled="!selected.length" @click="submit" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import UploadDownload from '@c/library/UploadDownload.vue'
import { relativeTime } from '@/util'
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import { mapGetters, mapActions } from 'vuex'
import { userFileStorageIntegrations } from '@c/integrations'

const defaultState = {
  visible: false,
  selected: [],
  query: '',
  doubleClick: ''
}

export default {
  name: 'IntegrationFileSearchModal',
  components: { Button, UploadDownload, TextInput, DocumentTag },
  mixins: [PaginatorConsumer],
  data: () => ({
    ...defaultState
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'userIntegrations']),
    selectedIds() {
      return this.selected.map((s) => s.uuid)
    },
    uploadProvider() {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    },
    providerObject() {
      return userFileStorageIntegrations[this.uploadProvider]
    },
    triggerText() {
      return `Select on ${this.providerObject?.title || ''}`
    },
    userIntegration() {
      return this.userIntegrations.find((ui) => ui.type === this.uploadProvider)
    }
  },
  methods: {
    ...mapActions(['getUserIntegrationFilesPaginator']),
    close() {
      this.visible = false
      this.$nextTick(() => {
        Object.assign(this.$data, defaultState)
        this.clearPaginatorConsumer()
      })
    },
    lastEdit(file) {
      return file.external_modified_date
        ? `Updated ${relativeTime(file.external_modified_date)}`
        : file.external_created_date
        ? `Created ${relativeTime(file.external_created_date)}`
        : ''
    },
    startFileSelect(connect) {
      if (this.userIntegration) this.showModal()
      else connect(true)
    },
    showModal() {
      this.visible = true
      this.setupPaginatorConsumer()
    },
    openExternal(file) {
      window.open(file.web_path, '_blank')
    },
    submit() {
      this.$emit('submit', this.selected)
      this.close()
    },
    getPaginator() {
      return this.getUserIntegrationFilesPaginator({
        integration_id: this.userIntegration.id,
        search: this.query,
        page_size: 50
      })
    },
    select(file) {
      if (this.doubleClick === file.uuid) {
        this.selected = [file]
        this.submit()
      } else {
        this.selected = this.selectedIds.includes(file.uuid)
          ? this.selected.filter((s) => s.uuid !== file.uuid)
          : [...this.selected, file]
        this.doubleClick = file.uuid
        setTimeout(() => (this.doubleClick = ''), 300)
      }
    },
    async setupPaginatorConsumer() {
      try {
        if (!this.visible) return
        this.consumptionLoading = true
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
        this.consumptionLoading = false
        this.handleScroll()
      } catch (e) {
        this.$console.debug('Error loading integration files', e)
        this.$toast.danger(e, 'loading files from integration')
      } finally {
        this.consumptionLoading = false
      }
    },
    handleScroll() {
      if (this.consumptionLoading || this.isDone) return
      const el = this.$refs.ifilelist
      if (el.scrollTop + el.clientHeight >= el.scrollHeight - 100)
        this.loadPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.ifile-search {
  &-trigger {
    &-icon {
      height: 1.2rem;
    }
  }

  background: white;
  width: min(60rem, calc(100vw - 2rem));
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-text {
      font-weight: 600;
      font-size: 1.25rem;
      margin-right: auto;
    }
  }
  &-content {
    display: flex;
    flex-flow: column nowrap;

    &-search {
      width: 50%;
      margin: 1.5rem 1.5rem 1rem;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      overflow-y: auto;
      max-height: 50vh;
      padding: 0 1.5rem;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        cursor: pointer;
        border-bottom: 1px solid rgba(#000, 0.08);

        &:last-child {
          border-bottom: none;
          margin-bottom: 2rem;
        }

        &:hover {
          background: rgba(#000, 0.04);
        }

        &.selected {
          background: rgba($primary, 0.08);
        }

        &-tag {
          height: 1.2rem;
          width: 1.2rem;
        }

        &-name {
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
        }

        &-link {
          user-select: none;
          cursor: pointer;
          filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
            saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
          height: 0.8rem;

          &:hover {
            transform: scale(1.1);
          }
        }

        &-date {
          color: #60666b;
          margin-left: auto;
        }
      }

      &-loading {
        position: relative;
        min-height: 5rem;
      }
    }
  }
  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}
::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
