<template>
  <b-modal active @close="close">
    <div class="resource-regenerate">
      <div class="resource-regenerate-header">
        <p class="resource-regenerate-header-title">
          Are you sure you want to regenerate this content
        </p>
        <Button icon="close" size="xs" type="grey" @click="close" />
      </div>
      <div class="resource-regenerate-content">
        This will archive the current document in your {{ integrationName }} and
        create a new version.
      </div>
      <div class="resource-regenerate-footer">
        <Button text="Cancel" type="white" :disabled="loading" @click="close" />
        <Button
          text="Regenerate"
          :loading="loading"
          @click="handleRegenerate"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PortfolioResourceModalRegenerate',
  components: { Button },
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    rootProps: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    integrationName() {
      return {
        sharepoint: 'SharePoint',
        google_drive: 'Google Drive'
      }[this.resource.integrationfile.integration]
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions([
      'regenerateOfferingResource',
      'regenerateReferenceResource',
      'regenerateInspirationResource'
    ]),
    close() {
      this.$emit('close')
    },
    async handleRegenerate() {
      try {
        this.loading = true
        const regenFunction = {
          offerings: this.regenerateOfferingResource,
          references: this.regenerateReferenceResource,
          inspirations: this.regenerateInspirationResource
        }[this.$route.params.ori_type]
        await regenFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.$route.params.ori_id,
          template_id: this.rootProps.templateId
        })
        this.$emit('regenerate', this.resource.uuid, {
          title: this.resource.title,
          integrationfile: {
            mimetype: this.resource.integrationfile.mimetype,
            creation_source: 'generated',
            external_created_date: new Date().toISOString()
          }
        })
      } catch (e) {
        this.$toast.error(e, 'regenerating content')
        this.$console.debug('Error regenerating content', e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-regenerate {
  background: white;
  border-radius: 6px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
  width: min(45rem, calc(100vw - 3rem));
  max-height: calc(100vh - 7rem);
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 4rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-content {
    flex: 1;
    overflow: auto;
    padding: 1.5rem;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    gap: 0.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
}
</style>
