var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", { attrs: { active: "" }, on: { close: _vm.close } }, [
    _c("div", { staticClass: "resource-regenerate" }, [
      _c(
        "div",
        { staticClass: "resource-regenerate-header" },
        [
          _c("p", { staticClass: "resource-regenerate-header-title" }, [
            _vm._v(" Are you sure you want to regenerate this content ")
          ]),
          _c("Button", {
            attrs: { icon: "close", size: "xs", type: "grey" },
            on: { click: _vm.close }
          })
        ],
        1
      ),
      _c("div", { staticClass: "resource-regenerate-content" }, [
        _vm._v(
          " This will archive the current document in your " +
            _vm._s(_vm.integrationName) +
            " and create a new version. "
        )
      ]),
      _c(
        "div",
        { staticClass: "resource-regenerate-footer" },
        [
          _c("Button", {
            attrs: { text: "Cancel", type: "white", disabled: _vm.loading },
            on: { click: _vm.close }
          }),
          _c("Button", {
            attrs: { text: "Regenerate", loading: _vm.loading },
            on: { click: _vm.handleRegenerate }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }