var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "upload-drop",
        class: {
          dragging: _vm.dragging,
          valid: _vm.dragDataValid,
          invalid: _vm.dragging && !_vm.dragDataValid,
          disabled: _vm.disabled || _vm.loading,
          loading: _vm.loading
        },
        style: Object.assign(
          {},
          _vm.minHeight ? { minHeight: _vm.minHeight } : {}
        ),
        on: {
          dragover: function($event) {
            $event.preventDefault()
          },
          dragenter: function($event) {
            $event.preventDefault()
            return _vm.handleDragenter.apply(null, arguments)
          },
          dragleave: function($event) {
            $event.preventDefault()
            return _vm.handleDragleave.apply(null, arguments)
          },
          drop: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.handleDrop.apply(null, arguments)
          }
        }
      },
      [
        _c("img", {
          staticClass: "upload-drop-icon",
          class: {
            dragging: _vm.dragging,
            valid: _vm.dragDataValid,
            invalid: _vm.dragging && !_vm.dragDataValid
          },
          attrs: { src: require("@/assets/icons/upload2-filled.svg"), alt: "" }
        }),
        _c("span", { staticClass: "upload-drop-text" }, [
          _vm._v(
            "Drag and drop your file" +
              _vm._s(_vm.maxCount === 1 ? "" : "s") +
              " here or"
          )
        ]),
        _c("Button", {
          attrs: {
            icon: "folder",
            text: "Browse...",
            type: "white",
            "icon-left": true
          },
          on: {
            click: function($event) {
              return _vm.$refs.fileinput.click()
            }
          }
        }),
        _vm.driveIntegration && _vm.showDrive
          ? _c(
              "div",
              { staticClass: "upload-drop-drive" },
              [
                _c("span", [_vm._v("or")]),
                _c("IntegrationFileSearchModal", {
                  on: { submit: _vm.handleIntegrationFileSelect }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.maxSize
          ? _c("span", { staticClass: "upload-drop-max" }, [
              _vm._v(_vm._s(_vm.maxSizeText))
            ])
          : _vm._e(),
        _c("input", {
          ref: "fileinput",
          staticClass: "upload-drop-input",
          attrs: { type: "file", multiple: "", accept: _vm.allowedExtensions },
          on: { change: _vm.handleFileinputChange }
        }),
        _vm.loading
          ? _c("b-loading", { attrs: { active: "", "is-full-page": false } })
          : _vm._e()
      ],
      1
    ),
    _vm.files.length
      ? _c(
          "div",
          { staticClass: "upload-list" },
          _vm._l(_vm.files, function(file, index) {
            return _c(
              "div",
              { key: file.name, staticClass: "upload-list-item" },
              [
                _c("p", { staticClass: "upload-list-item-name" }, [
                  _vm._v(" " + _vm._s(file.name) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "upload-list-item-action" },
                  [
                    _vm._t("action", null, { file: file, index: index }),
                    _vm.showRemove(file)
                      ? _c("Button", {
                          attrs: { icon: "bin", type: "grey", size: "xs" },
                          on: {
                            click: function() {
                              return _vm.removeFile(file.name)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.maxCount
      ? _c("p", { staticClass: "upload-max-count" }, [
          _vm._v(
            " Max " +
              _vm._s(_vm.maxCount) +
              " file" +
              _vm._s(_vm.maxCount === 1 ? "" : "s") +
              " can be chosen "
          )
        ])
      : _vm._e(),
    _c("p", { staticClass: "upload-allowed-types" }, [
      _vm._v(" Allowed file types: " + _vm._s(_vm.allowedFileTypesText) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }