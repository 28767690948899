var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPublishButton
        ? _c(
            "div",
            { staticClass: "portfolio-resource-publish" },
            [
              _c("p", { staticClass: "portfolio-resource-publish-text" }, [
                _vm._v(
                  " Please review this generated content before publishing it. "
                )
              ]),
              _vm.showRegenerate
                ? _c("Button", {
                    attrs: {
                      text: "Regenerate",
                      icon: "refresh",
                      type: "white",
                      "icon-left": true
                    },
                    on: { click: _vm.handleRegenerate }
                  })
                : _vm._e(),
              _c("Button", {
                attrs: {
                  text: "Approve & publish",
                  "icon-left": true,
                  icon: "check-circle"
                },
                on: { click: _vm.handlePublish }
              })
            ],
            1
          )
        : _vm.showRegenerate
        ? _c(
            "div",
            { staticClass: "portfolio-resource-regenerate" },
            [
              _vm._v(
                " Regenerating will archive the current document and create a new version. "
              ),
              _c("Button", {
                attrs: {
                  text: "Regenerate",
                  icon: "refresh",
                  type: "white",
                  "icon-left": true
                },
                on: { click: _vm.handleRegenerate }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showRegenerateModal
        ? _c("PortfolioResourceModalRegenerate", {
            attrs: {
              resource: _vm.resource,
              "root-props": _vm.resourceModalRootProps
            },
            on: {
              close: function() {
                return (_vm.showRegenerateModal = false)
              },
              regenerate: function(id, res) {
                return _vm.$emit("regenerate", id, res)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }