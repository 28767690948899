<template>
  <div class="upload-wrapper">
    <div
      class="upload-drop"
      :class="{
        dragging,
        valid: dragDataValid,
        invalid: dragging && !dragDataValid,
        disabled: disabled || loading,
        loading
      }"
      :style="{ ...(minHeight ? { minHeight } : {}) }"
      @dragover.prevent
      @dragenter.prevent="handleDragenter"
      @dragleave.prevent="handleDragleave"
      @drop.stop.prevent="handleDrop"
    >
      <img
        src="@/assets/icons/upload2-filled.svg"
        alt=""
        class="upload-drop-icon"
        :class="{
          dragging,
          valid: dragDataValid,
          invalid: dragging && !dragDataValid
        }"
      />
      <span class="upload-drop-text"
        >Drag and drop your file{{ maxCount === 1 ? '' : 's' }} here or</span
      >
      <Button
        icon="folder"
        text="Browse..."
        type="white"
        :icon-left="true"
        @click="$refs.fileinput.click()"
      />
      <div v-if="driveIntegration && showDrive" class="upload-drop-drive">
        <span>or</span>
        <IntegrationFileSearchModal @submit="handleIntegrationFileSelect" />
      </div>
      <span v-if="maxSize" class="upload-drop-max">{{ maxSizeText }}</span>
      <input
        ref="fileinput"
        type="file"
        class="upload-drop-input"
        multiple
        :accept="allowedExtensions"
        @change="handleFileinputChange"
      />
      <b-loading v-if="loading" active :is-full-page="false" />
    </div>
    <div v-if="files.length" class="upload-list">
      <div
        v-for="(file, index) in files"
        :key="file.name"
        class="upload-list-item"
      >
        <p class="upload-list-item-name">
          {{ file.name }}
        </p>
        <div class="upload-list-item-action">
          <slot name="action" :file="file" :index="index"> </slot>
          <Button
            v-if="showRemove(file)"
            icon="bin"
            type="grey"
            size="xs"
            @click="() => removeFile(file.name)"
          />
        </div>
      </div>
    </div>
    <p v-if="maxCount" class="upload-max-count">
      Max {{ maxCount }} file{{ maxCount === 1 ? '' : 's' }} can be chosen
    </p>
    <p class="upload-allowed-types">
      Allowed file types: {{ allowedFileTypesText }}
    </p>
  </div>
</template>

<script>
import IntegrationFileSearchModal from '@/views-v2/integrations/IntegrationFileSearchModal.vue'
import Button from './Button.vue'
import { mapGetters } from 'vuex'

const allowedFileTypes = [
  // PowerPoint
  { extension: 'ppt', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: 'pptx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  // Word
  { extension: 'doc', mimeType: 'application/msword' },
  {
    extension: 'docx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  // Excel
  { extension: 'xls', mimeType: 'application/vnd.ms-excel' },
  {
    extension: 'xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  // PDF
  { extension: 'pdf', mimeType: 'application/pdf' },
  // MP4
  { extension: 'mp4', mimeType: 'video/mp4' },
  // Quicktime
  { extension: 'mov', mimeType: 'video/quicktime' },
  { extension: 'qt', mimeType: 'video/quicktime' }
]

export default {
  name: 'UploadDropzone',
  components: { Button, IntegrationFileSearchModal },
  props: {
    filetypes: {
      type: Array,
      default: () => ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf'],
      validator: filetypes =>
        filetypes.every(filetype =>
          allowedFileTypes.some(
            allowedFiletype => allowedFiletype.extension === filetype
          )
        )
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 0
    },
    maxSizeForTotal: {
      type: Boolean,
      default: false
    },
    maxCount: {
      type: Number,
      default: 0
    },
    showDelete: {
      type: [Boolean, Function],
      default: true
    },
    showDrive: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dragging: 0,
    dragData: null,
    files: []
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    filetypeObjects() {
      return allowedFileTypes.filter(filetype =>
        this.filetypes.includes(filetype.extension)
      )
    },
    allowedMimeTypes() {
      return this.filetypeObjects.map(filetype => filetype.mimeType)
    },
    allowedExtensions() {
      return this.filetypeObjects.map(filetype => `.${filetype.extension}`)
    },
    allowedFileTypesText() {
      return `${this.filetypes.slice(0, -1).join(', ')}${
        this.filetypes.length > 1 ? ' and ' : ''
      }${this.filetypes.slice(-1)}`
    },
    dragDataFiles() {
      return this.dragData.items?.length
        ? [...this.dragData.items]
        : [...this.dragData.files]
    },
    dragDataMimetypes() {
      return this.dragDataFiles.map(file => file.type)
    },
    dragDataValid() {
      if (!this.dragData || !this.dragDataMimetypes?.length) return false
      return (
        this.dragDataMimetypes.every(type =>
          this.allowedMimeTypes.includes(type)
        ) &&
        (!this.maxCount || this.dragDataFiles.length <= this.maxCount)
      )
    },
    maxSizeText() {
      if (!this.files.length || !this.maxSizeForTotal)
        return `Max size of ${this.maxSize}MB`
      let totalSize =
        this.files.reduce((acc, file) => acc + file.size, 0) / 1024 / 1024
      totalSize =
        totalSize < 1
          ? `${Math.round(totalSize * 1024 * 100) / 100}KB`
          : `${Math.round(totalSize * 100) / 100}MB`
      return `${totalSize} / ${this.maxSize}MB`
    },
    driveIntegration() {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    }
  },
  methods: {
    showError(e) {
      this.$toast.danger(
        'Error uploading file',
        e ||
          'Something went wrong uploading your file. Please try again later or contact support.'
      )
    },
    handleDragenter(ev) {
      if (!this.dragging) this.dragData = ev.dataTransfer
      this.dragging++
    },
    handleDragleave() {
      this.dragging--
      if (!this.dragging) this.dragData = null
    },
    handleDrop(event) {
      if (!this.dragDataValid) {
        this.showError('Invalid file type')
        return
      } else if (
        !!this.maxCount &&
        this.files.length + this.dragDataFiles.length > this.maxCount
      ) {
        this.showError('Too many files selected')
        return
      }
      if (this.dragDataValid) {
        const files = event.dataTransfer.files
        this.$emit('upload', files)
        this.addFiles(files)
      }
      this.dragging = 0
      this.dragData = null
    },
    handleFileinputChange(event) {
      if (!event.target.files.length) {
        this.showError('No files selected')
        return
      } else if (
        !!this.maxCount &&
        this.files.length + event.target.files.length > this.maxCount
      ) {
        this.showError('Too many files selected')
        return
      }
      this.$emit('upload', event.target.files)
      this.addFiles(event.target.files)
    },
    handleIntegrationFileSelect(files) {
      if (!files.length) {
        this.showError('No files selected')
        return
      } else if (
        !!this.maxCount &&
        this.files.length + files.length > this.maxCount
      ) {
        this.showError('Too many files selected')
        return
      }
      let newFiles = files.filter(
        file => !this.files.some(f => f.name === file.name)
      )
      if (!newFiles.length) return
      this.$emit('upload', newFiles, true)
      this.addFiles(
        newFiles.map(file => {
          let f = new File([], file.name, { type: file.type })
          Object.defineProperty(f, 'size', { value: file.file_size })
          Object.defineProperty(f, 'is_drive', { value: true })
          return f
        })
      )
    },
    addFiles(files) {
      const newFiles = Array.from(files).filter(
        file => !this.files.some(f => f.name === file.name)
      )
      if (!newFiles.length) return
      this.files = [...this.files, ...newFiles]
      this.setInputFiles()
    },
    showRemove(file) {
      return typeof this.showDelete === 'function'
        ? this.showDelete(file)
        : this.showDelete
    },
    removeFile(name) {
      const file = this.files.find(file => file.name === name)
      this.$emit('remove', file, file.is_drive)
      this.files = this.files.filter(file => file.name !== name)
      this.setInputFiles()
    },
    setInputFiles() {
      const dt = new DataTransfer()
      this.files.forEach(file => dt.items.add(file))
      this.$refs.fileinput.files = dt.files
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  &-wrapper {
    height: 100%;
  }

  &-drop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem;
    border: 1px dashed rgba(#000, 0.08);
    border-radius: 8px;
    transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
    color: #60666b;
    position: relative;

    &:hover {
      background: rgba(#000, 0.02);
      border-color: rgba(#000, 0.16);
    }

    &:not(.disabled) {
      &.dragging {
        background: rgba($primary, 0.02);
        border-color: $primary;
      }

      &.valid {
        background: rgba(#24ce85, 0.02);
        border-color: #24ce85;
        color: #24ce85;
      }

      &.invalid {
        background: rgba(#fc0d1b, 0.02);
        border-color: #fc0d1b;
        color: #fc0d1b;
      }
    }

    &.disabled {
      background: rgba(#000, 0.02);
      border-color: rgba(#000, 0.08);
      filter: grayscale(1) brightness(0.8) opacity(0.3);
      cursor: default;
    }

    &.loading {
      pointer-events: none;
      position: relative;
    }

    &-icon {
      height: 2.85rem;
      opacity: 0.5;
      transition: filter 0.2s ease-in-out;

      &.valid {
        filter: brightness(0) saturate(100%) invert(67%) sepia(63%)
          saturate(502%) hue-rotate(100deg) brightness(88%) contrast(94%);
      }

      &.invalid {
        filter: brightness(0) saturate(100%) invert(19%) sepia(99%)
          saturate(4984%) hue-rotate(349deg) brightness(95%) contrast(107%);
      }
    }

    &-text {
      color: #60666b;
    }

    &-drive {
      display: contents;
      color: #60666b;
    }

    &-input {
      display: none;
    }

    &-max {
      font-size: 0.85rem;
      color: #60666b;
    }
  }

  &-max-count,
  &-allowed-types {
    font-size: 0.85rem;
    color: #60666b;
  }

  &-list {
    display: flex;
    flex-flow: column nowrap;
    max-height: 15vh;
    overflow-y: auto;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem;

      &:hover {
        background: rgba(#000, 0.04);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.08);
      }

      &-action {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}
</style>
