<template>
  <div class="portfolio-owners-wrapper">
    <div
      class="portfolio-owners"
      :class="{
        empty: ownersEmpty,
        'can-click': !ownersEmpty || canEdit,
        'can-edit': canEdit,
        'has-more': owners.length > 2
      }"
      @click="openOwnersModal"
    >
      <div
        v-for="owner in owners.slice(0, 2)"
        :key="owner.uuid"
        class="portfolio-owners-owner"
      >
        <b-tooltip
          :label="$umodel.full_name(owner)"
          position="is-top"
          type="is-dark"
        >
          <Avatar :user="owner" fallback-icon="user" />
        </b-tooltip>
      </div>
      <b-tooltip
        v-if="owners.length > 2"
        :label="moreOwnersTooltip"
        position="is-top"
        type="is-dark"
      >
        <p class="portfolio-owners-more">
          {{ `+${owners.length - 2}` }}
        </p>
      </b-tooltip>
      <img
        v-if="ownersEmpty"
        src="@/assets/icons/plus-medium.svg"
        alt=""
        class="portfolio-owners-add"
      />
    </div>
    <ORIOwnersModal
      :visible="showOwnersModal"
      :item="item"
      :type="typeSingular"
      :editable="canEdit"
      @close="() => (showOwnersModal = false)"
      @edit="handleOwnersEdit"
    />
  </div>
</template>

<script>
import ORIOwnersModal from '../ori/ORIOwnersModal.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'PortfolioItemOwners',
  components: { ORIOwnersModal, Avatar },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'offerings'
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showOwnersModal: false
  }),
  computed: {
    owners() {
      return (this.item.owners || []).filter((o) => !o.is_inherited)
    },
    ownersEmpty() {
      return !this.owners.length
    },
    typeSingular() {
      return {
        offerings: 'offering',
        references: 'reference',
        inspirations: 'inspiration'
      }[this.type]
    },
    moreOwnersTooltip() {
      if (this.owners.length <= 2) return ''
      return this.owners
        .slice(2)
        .map((o) => this.$umodel.full_name(o))
        .join(', ')
    }
  },
  methods: {
    openOwnersModal() {
      if (!this.ownersEmpty || this.canEdit) this.showOwnersModal = true
    },
    handleOwnersEdit(item) {
      this.showOwnersModal = false
      this.$emit('edit', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-owners {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0.5rem;
  max-width: 6rem;
  width: fit-content;
  border-radius: 4px;
  transition: background 0.2s ease;
  border: 1px solid transparent;

  &.can-click {
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.08);
    }

    &.empty {
      &:hover {
        border: 1px dashed rgba(#000, 0.08);

        & .portfolio-owners-add {
          opacity: 0.5;
        }
      }
    }
  }

  &.can-edit {
    &.empty {
      &:hover {
        & .portfolio-owners-add {
          opacity: 0.5;
        }
      }
    }
  }

  &.empty {
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.empty) {
    padding: 0.5rem 0.5rem 2px 1rem;

    &.has-more {
      padding: 0.5rem 6rem 2px 1rem;
    }
  }

  &-wrapper {
    margin-left: -0.5rem;
  }

  &-more {
    white-space: nowrap;
    padding: 2px 0.25rem 0.5rem;
  }

  &-add {
    opacity: 0;
    transition: opacity 0.2s ease;
    height: 1.35rem;
    width: 1.35rem;
  }

  &-owner {
    margin-left: -0.5rem;
  }
}
</style>
