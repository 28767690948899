var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.uploadProvider
        ? _c("UploadDownload", {
            on: { connected: _vm.showModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var connect = ref.connect
                    return [
                      _vm._t(
                        "trigger",
                        function() {
                          return [
                            _c("Button", {
                              attrs: { text: _vm.triggerText, type: "white" },
                              on: {
                                click: function() {
                                  return _vm.startFileSelect(connect)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "left",
                                    fn: function() {
                                      return [
                                        _c("img", {
                                          staticClass:
                                            "ifile-search-trigger-icon",
                                          attrs: {
                                            src: _vm.providerObject.image,
                                            alt: ""
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        },
                        {
                          select: function() {
                            return _vm.startFileSelect(connect)
                          }
                        }
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: { "can-cancel": ["escape", "outside"] },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("div", { staticClass: "ifile-search" }, [
            _c(
              "div",
              { staticClass: "ifile-search-header" },
              [
                _c("p", { staticClass: "ifile-search-header-text" }, [
                  _vm._v(
                    " Select files on " +
                      _vm._s(
                        _vm.providerObject ? _vm.providerObject.title : ""
                      ) +
                      " "
                  )
                ]),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: { click: _vm.close }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ifile-search-content" },
              [
                _c("TextInput", {
                  staticClass: "ifile-search-content-search",
                  attrs: {
                    placeholder:
                      "Search in " +
                      (_vm.providerObject ? _vm.providerObject.title : ""),
                    "left-icon": "search",
                    disabled: _vm.consumptionLoading
                  },
                  on: { submit: _vm.resetPaginatorConsumer },
                  model: {
                    value: _vm.query,
                    callback: function($$v) {
                      _vm.query = $$v
                    },
                    expression: "query"
                  }
                }),
                _c(
                  "div",
                  {
                    ref: "ifilelist",
                    staticClass: "ifile-search-content-list",
                    on: { wheel: _vm.handleScroll }
                  },
                  [
                    _vm._l(_vm.paginatorData, function(file) {
                      return _c(
                        "div",
                        {
                          key: file.uuid,
                          staticClass: "ifile-search-content-list-item",
                          class: {
                            selected: _vm.selectedIds.includes(file.uuid)
                          },
                          on: {
                            click: function() {
                              return _vm.select(file)
                            }
                          }
                        },
                        [
                          _c("DocumentTag", {
                            staticClass: "ifile-search-content-list-item-tag",
                            attrs: { mimetype: file.mimetype }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "ifile-search-content-list-item-name"
                            },
                            [_vm._v(_vm._s(file.name))]
                          ),
                          _c("img", {
                            staticClass: "ifile-search-content-list-item-link",
                            attrs: {
                              src: require("@/assets/icons/external-bold.svg"),
                              alt: ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {
                                  return _vm.openExternal(file)
                                }.apply(null, arguments)
                              }
                            }
                          }),
                          _c(
                            "p",
                            {
                              staticClass: "ifile-search-content-list-item-date"
                            },
                            [_vm._v(" " + _vm._s(_vm.lastEdit(file)) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    _vm.consumptionLoading
                      ? _c(
                          "div",
                          { staticClass: "ifile-search-content-list-loading" },
                          [
                            _c("b-loading", {
                              attrs: {
                                active: _vm.consumptionLoading,
                                "is-full-page": false
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ifile-search-footer" },
              [
                _c("Button", {
                  attrs: { text: "Cancel", type: "white" },
                  on: { click: _vm.close }
                }),
                _c("Button", {
                  attrs: { text: "Select", disabled: !_vm.selected.length },
                  on: { click: _vm.submit }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }