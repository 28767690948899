var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.visible
        ? _c("div", { staticClass: "ori-owner-modal" }, [
            _c(
              "div",
              { staticClass: "ori-owner-modal-content" },
              [
                _c("p", { staticClass: "ori-owner-modal-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.editable ? "Change owners" : "Owners") +
                      " "
                  )
                ]),
                _c("ORIOwnersEdit", {
                  attrs: {
                    title: "Owners",
                    "default-owners": _vm.owners,
                    editable: _vm.editable,
                    clickable: true
                  },
                  on: {
                    submit: function($event) {
                      _vm.owners = $event
                    }
                  }
                })
              ],
              1
            ),
            _vm.editable
              ? _c(
                  "div",
                  { staticClass: "ori-owner-modal-footer" },
                  [
                    _c("Button", {
                      attrs: {
                        text: "Cancel",
                        type: "white",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function() {
                          return _vm.$emit("close")
                        }
                      }
                    }),
                    _c("Button", {
                      attrs: {
                        text: "Save",
                        loading: _vm.loading,
                        disabled: !_vm.ownersChanged
                      },
                      on: { click: _vm.saveOwners }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }