<template>
  <div class="portfolio-name">
    <component :is="clickable ? 'router-link' : 'div'" :to="route">
      <div class="portfolio-name-wrapper" :class="{ clickable }">
        <div class="portfolio-name-visual">
          <img
            :src="image || require('@/assets/icons/globe.svg')"
            alt=""
            class="portfolio-name-visual-img"
            :class="{
              'full-cover': !!image,
              'icon-grey': !image
            }"
          />
        </div>
        <div class="portfolio-name-content">
          <div class="portfolio-name-content-title">
            {{ item.name }}
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: 'PortfolioItemName',
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'offerings'
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    image() {
      const org = this.item?.account?.organisation
      return this.item.image || org?.icon || org?.logo || ''
    },
    route() {
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: this.type,
          ori_id: this.item.uuid
        },
        query: {
          ...(this.$route.query?.view ? { view: this.$route.query?.view } : {})
        }
      }
    }
  },
  methods: {
    handleClick() {
      if (this.clickable) this.$emit('view')
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-name {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.15rem;

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.15rem;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 4px;
    width: max(25rem, 30vw);

    &.clickable {
      cursor: pointer;
      transition: background 0.2s ease;

      &:hover {
        background: rgba(#000, 0.08);
      }
    }
  }

  &-visual {
    width: 2.85rem;
    min-width: 2.85rem;
    height: 2.85rem;
    min-height: 2.85rem;
    border-radius: 4px;
    overflow: hidden;
    background: #f9f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-img {
      width: 1.75rem;
      height: 1.75rem;

      &.full-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.icon-grey {
        opacity: 0.5;
      }
    }

    &-edit {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;

    &-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      font-weight: 500;
    }
  }
}
</style>
