var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-owners-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "portfolio-owners",
          class: {
            empty: _vm.ownersEmpty,
            "can-click": !_vm.ownersEmpty || _vm.canEdit,
            "can-edit": _vm.canEdit,
            "has-more": _vm.owners.length > 2
          },
          on: { click: _vm.openOwnersModal }
        },
        [
          _vm._l(_vm.owners.slice(0, 2), function(owner) {
            return _c(
              "div",
              { key: owner.uuid, staticClass: "portfolio-owners-owner" },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$umodel.full_name(owner),
                      position: "is-top",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Avatar", {
                      attrs: { user: owner, "fallback-icon": "user" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.owners.length > 2
            ? _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.moreOwnersTooltip,
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _c("p", { staticClass: "portfolio-owners-more" }, [
                    _vm._v(" " + _vm._s("+" + (_vm.owners.length - 2)) + " ")
                  ])
                ]
              )
            : _vm._e(),
          _vm.ownersEmpty
            ? _c("img", {
                staticClass: "portfolio-owners-add",
                attrs: {
                  src: require("@/assets/icons/plus-medium.svg"),
                  alt: ""
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c("ORIOwnersModal", {
        attrs: {
          visible: _vm.showOwnersModal,
          item: _vm.item,
          type: _vm.typeSingular,
          editable: _vm.canEdit
        },
        on: {
          close: function() {
            return (_vm.showOwnersModal = false)
          },
          edit: _vm.handleOwnersEdit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }