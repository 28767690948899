<template>
  <div>
    <div v-if="showPublishButton" class="portfolio-resource-publish">
      <p class="portfolio-resource-publish-text">
        Please review this generated content before publishing it.
      </p>
      <Button
        v-if="showRegenerate"
        text="Regenerate"
        icon="refresh"
        type="white"
        :icon-left="true"
        @click="handleRegenerate"
      />
      <Button
        :text="`Approve & publish`"
        :icon-left="true"
        icon="check-circle"
        @click="handlePublish"
      />
    </div>
    <div v-else-if="showRegenerate" class="portfolio-resource-regenerate">
      Regenerating will archive the current document and create a new version.
      <Button
        text="Regenerate"
        icon="refresh"
        type="white"
        :icon-left="true"
        @click="handleRegenerate"
      />
    </div>
    <PortfolioResourceModalRegenerate
      v-if="showRegenerateModal"
      :resource="resource"
      :root-props="resourceModalRootProps"
      @close="() => (showRegenerateModal = false)"
      @regenerate="(id, res) => $emit('regenerate', id, res)"
    />
  </div>
</template>

<script>
import { editKeyContentTemplateResource } from '@/services/keyContentService'
import Button from '@c/library/Button.vue'
import { downloadableMimetypes } from '@c/mimetypes'
import { mapState } from 'vuex'
import PortfolioResourceModalRegenerate from './PortfolioResourceModalRegenerate.vue'

export default {
  name: 'PortfolioResourceModalHeader',
  components: {
    Button,
    PortfolioResourceModalRegenerate
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    forceHide: false,
    showRegenerateModal: false
  }),
  computed: {
    ...mapState({
      resourceModalRootProps: state =>
        state.resourceModals.resourceModal?.rootProps || {}
    }),
    showPublishButton() {
      return (
        (!this.forceHide && this.resourceModalRootProps.needsReview) || false
      )
    },
    showRegenerate() {
      const { creation_source, mimetype } = this.resource.integrationfile
      return (
        creation_source === 'generated' &&
        downloadableMimetypes.includes(mimetype) &&
        this.resourceModalRootProps.canEdit
      )
    }
  },
  methods: {
    async handlePublish() {
      try {
        const res = await editKeyContentTemplateResource({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.resourceModalRootProps.templateId,
          ori_id: this.resourceModalRootProps.oriId,
          ori_type: this.resourceModalRootProps.oriType,
          status: 'published',
          act_as: this.$route.query.act_as
        })
        let bc = new BroadcastChannel('uman_template_resource_publish')
        bc.postMessage({ event: 'published', result: res })
        this.$toast.success(
          'Content published',
          'Your content has been published successfully.'
        )
        this.forceHide = true
      } catch (e) {
        this.$console.debug('Publishing of content failed', e)
        this.$toast.error(e, 'publishing your content')
      }
    },
    handleRegenerate() {
      this.showRegenerateModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-resource {
  &-publish {
    margin-bottom: 1.5rem;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    border: 1px solid $primary;
    background: #ebf1ff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;

    &-text {
      font-weight: 600;
      font-size: 1.15rem;
      color: $primary;
      margin-right: auto;
    }
  }

  &-regenerate {
    color: #60666b;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #f1f2f3;
    border-radius: 4px;
  }
}
</style>
