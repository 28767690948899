var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-name" },
    [
      _c(
        _vm.clickable ? "router-link" : "div",
        { tag: "component", attrs: { to: _vm.route } },
        [
          _c(
            "div",
            {
              staticClass: "portfolio-name-wrapper",
              class: { clickable: _vm.clickable }
            },
            [
              _c("div", { staticClass: "portfolio-name-visual" }, [
                _c("img", {
                  staticClass: "portfolio-name-visual-img",
                  class: {
                    "full-cover": !!_vm.image,
                    "icon-grey": !_vm.image
                  },
                  attrs: {
                    src: _vm.image || require("@/assets/icons/globe.svg"),
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "portfolio-name-content" }, [
                _c("div", { staticClass: "portfolio-name-content-title" }, [
                  _vm._v(" " + _vm._s(_vm.item.name) + " ")
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }