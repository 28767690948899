<template>
  <div
    class="seg-button-wrapper"
    :class="{
      disabled,
      loading,
      'full-width': fullWidth
    }"
  >
    <button
      class="seg-button left"
      :class="{ [typeLeft]: true, [size]: true, 'icon-only': !textLeft }"
      @click="$emit('click-left')"
    >
      <span v-if="textLeft" class="seg-button-text">{{ textLeft }}</span>
      <img
        v-if="iconLeft"
        :src="require(`@/assets/icons/${iconLeft}.svg`)"
        alt=""
        class="seg-button-icon"
        :class="{ 'apply-filter': filterIcon }"
      />
    </button>
    <div class="seg-button-divider"></div>
    <button
      class="seg-button right"
      :class="{
        [typeRight || typeLeft]: true,
        [size]: true,
        'icon-only': !textRight
      }"
      @click="$emit('click-right')"
    >
      <img
        v-if="iconRight"
        :src="require(`@/assets/icons/${iconRight}.svg`)"
        alt=""
        class="seg-button-icon"
        :class="{ 'apply-filter': filterIcon }"
      />
      <span v-if="textRight" class="seg-button-text">{{ textRight }}</span>
    </button>
    <b-loading v-if="loading" :is-full-page="false" active>
      <img src="@/assets/icons/spinner.svg" alt="" class="seg-button-loading" />
    </b-loading>
  </div>
</template>

<script>
export default {
  name: 'SegmentedButton',
  props: {
    textLeft: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    textRight: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    typeLeft: {
      type: String,
      default: 'primary',
      validator: (s) =>
        ['primary', 'light', 'light-solid', 'white', 'grey', 'black'].includes(
          s
        )
    },
    typeRight: {
      type: String,
      default: 'primary',
      validator: (s) =>
        ['primary', 'light', 'light-solid', 'white', 'grey', 'black'].includes(
          s
        )
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xs', 's', 'm', 'l'].includes(s)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    filterIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.seg-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  flex: 20;
  background: none;
  border: none;
  outline: none;

  &.left {
    border-top-left-radius: 999rem;
    border-bottom-left-radius: 999rem;
  }

  &.right {
    border-top-right-radius: 999rem;
    border-bottom-right-radius: 999rem;
  }

  &-icon {
    transition: all 0.2s ease;
  }

  &-text {
    transition: all 0.2s ease;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
  }

  &-divider {
    width: 1px;
    background: white;
  }

  &-wrapper {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 999rem;
    outline: inherit;
    transition: all 0.2s ease;
    position: relative;
    height: fit-content;
    width: fit-content;
    overflow: hidden;

    &.disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }

    &.loading {
      cursor: default;
      filter: grayscale(100%);
      pointer-events: none;
    }

    &-loading {
      height: 1.2rem;
      filter: brightness(20%);
      animation: spin 0.75s linear infinite;
    }

    &.full-width {
      width: 100%;
    }
  }

  &.primary {
    background: $primary;
    color: white;
    border: none;
    & .seg-button-text {
      color: white;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }

    &:hover {
      background: darken($primary, 15%);
    }
  }

  &.light {
    background: rgba(#ebf1ff, 0%);
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      background: #ebf1ff;
    }
  }
  &.light-solid {
    background: #ebf1ff;
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      background: darken(#ebf1ff, 5%);
    }
  }

  &.white {
    background: white;
    border: 1px solid #dddfe2;

    & .seg-button-text {
      color: #60666b;
    }

    & .seg-button-divider {
      background: #60666b;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &:hover {
      background: darken(white, 6%);
      & .seg-button-text {
        color: #303032;
      }

      & .seg-button-icon.apply-filter {
        filter: brightness(0) saturate(100%) invert(11%) sepia(4%)
          saturate(615%) hue-rotate(202deg) brightness(101%) contrast(81%);
      }
    }
  }

  &.grey {
    background: transparent;
    border: none;

    & .seg-button-text {
      color: #60666b;
    }

    & .seg-button-divider {
      background: #60666b;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &:hover {
      background: rgba(#000, 6%);
      & .seg-button-text {
        color: #303032;
      }

      & .seg-button-icon.apply-filter {
        filter: brightness(0) saturate(100%) invert(11%) sepia(4%)
          saturate(615%) hue-rotate(202deg) brightness(101%) contrast(81%);
      }
    }
  }

  &.black {
    background: rgba(#000, 64%);
    border: none;

    & .seg-button-text {
      color: white;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }

    &:hover {
      background: black;
    }
  }

  &.light-solid {
    background: #ebf1ff;
    border: none;

    & .seg-button-text {
      color: $primary;
    }

    & .seg-button-divider {
      background: $primary;
    }

    & .seg-button-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      background: darken(#ebf1ff, 5%);
    }
  }

  &.xs {
    padding: 0.35rem 0.85rem;

    & .seg-button-text {
      font-size: 0.85rem;
    }

    & .seg-button-icon {
      height: 1.1rem;
      width: 1.1rem;
      min-width: 1.1rem;
      min-height: 1.1rem;
      object-fit: contain;
    }
  }

  &.s {
    padding: 0.35rem 1.15rem;

    & .seg-button-text {
      font-size: 1rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.m {
    padding: 0.8rem 1.45rem;

    & .seg-button-text {
      font-size: 1rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.l {
    padding: 0.95rem 1.7rem;

    & .seg-button-text {
      font-size: 1.15rem;
    }

    & .seg-button-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.icon-only {
    flex: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
