var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "seg-button-wrapper",
      class: {
        disabled: _vm.disabled,
        loading: _vm.loading,
        "full-width": _vm.fullWidth
      }
    },
    [
      _c(
        "button",
        {
          staticClass: "seg-button left",
          class:
            ((_obj = {}),
            (_obj[_vm.typeLeft] = true),
            (_obj[_vm.size] = true),
            (_obj["icon-only"] = !_vm.textLeft),
            _obj),
          on: {
            click: function($event) {
              return _vm.$emit("click-left")
            }
          }
        },
        [
          _vm.textLeft
            ? _c("span", { staticClass: "seg-button-text" }, [
                _vm._v(_vm._s(_vm.textLeft))
              ])
            : _vm._e(),
          _vm.iconLeft
            ? _c("img", {
                staticClass: "seg-button-icon",
                class: { "apply-filter": _vm.filterIcon },
                attrs: {
                  src: require("@/assets/icons/" + _vm.iconLeft + ".svg"),
                  alt: ""
                }
              })
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "seg-button-divider" }),
      _c(
        "button",
        {
          staticClass: "seg-button right",
          class:
            ((_obj$1 = {}),
            (_obj$1[_vm.typeRight || _vm.typeLeft] = true),
            (_obj$1[_vm.size] = true),
            (_obj$1["icon-only"] = !_vm.textRight),
            _obj$1),
          on: {
            click: function($event) {
              return _vm.$emit("click-right")
            }
          }
        },
        [
          _vm.iconRight
            ? _c("img", {
                staticClass: "seg-button-icon",
                class: { "apply-filter": _vm.filterIcon },
                attrs: {
                  src: require("@/assets/icons/" + _vm.iconRight + ".svg"),
                  alt: ""
                }
              })
            : _vm._e(),
          _vm.textRight
            ? _c("span", { staticClass: "seg-button-text" }, [
                _vm._v(_vm._s(_vm.textRight))
              ])
            : _vm._e()
        ]
      ),
      _vm.loading
        ? _c("b-loading", { attrs: { "is-full-page": false, active: "" } }, [
            _c("img", {
              staticClass: "seg-button-loading",
              attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }